unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, Vcl.Imaging.pngimage, Vcl.Imaging.jpeg;

type
  TForm1 = class(TForm)
    WebLabel1: TLabel;
    WebLinkLabel2: TLinkLabel;
    WebImageControl1: TImageControl;
    WebLabel2: TLabel;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLinkLabel2 := TLinkLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLabel2 := TLabel.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLinkLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 136;
    WebLabel1.Width := 274;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Willkommen bei der Cadia-Software GmbH';
    WebLabel1.ElementClassName := 'h4';
    WebLabel1.ElementLabelClassName := 'h4';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthStyle := ssAuto;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLinkLabel2.SetParentComponent(Self);
    WebLinkLabel2.Name := 'WebLinkLabel2';
    WebLinkLabel2.Left := 8;
    WebLinkLabel2.Top := 280;
    WebLinkLabel2.Width := 104;
    WebLinkLabel2.Height := 18;
    WebLinkLabel2.ElementClassName := 'link-info';
    WebLinkLabel2.ElementFont := efCSS;
    WebLinkLabel2.HeightPercent := 100.000000000000000000;
    WebLinkLabel2.WidthPercent := 100.000000000000000000;
    WebLinkLabel2.Caption := 'https://cadia.de';
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 8;
    WebImageControl1.Width := 561;
    WebImageControl1.Height := 89;
    WebImageControl1.HeightStyle := ssAuto;
    WebImageControl1.WidthStyle := ssAuto;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 2;
    WebImageControl1.ElementFont := efCSS;
    WebImageControl1.Picture.LoadFromFile('Unit1.WebImageControl1.Picture.jpg');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 184;
    WebLabel2.Width := 321;
    WebLabel2.Height := 73;
    WebLabel2.Caption := 'An dieser Webpr'#228'senz wird zur Zeit gearbeitet, alles Wissenwerte finden Sie auf unserer Seite: ';
    WebLabel2.ElementClassName := 'h6';
    WebLabel2.ElementLabelClassName := 'h6';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WordWrap := True;
    WebLabel2.WidthStyle := ssAuto;
    WebLabel2.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLinkLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
  end;
end;

end.
